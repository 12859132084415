import { object, func, string } from "prop-types"
import { isEmpty, minBy, maxBy, find } from "lodash"
import { Checkbox } from "source/registrations/CheckableInput"
import { Select } from "source/shared/components"
import { NumberSpinner } from "@planningcenter/doxy-web"
import { css } from "@emotion/react"

const addOnVariationOptions = (addOnVariations) => [
  { label: "Select option", value: "" },
  ...addOnVariations.map((variation) => ({
    label: `${variation.name} ${
      variation.priceCents > 0 ? `(${variation.priceString})` : ""
    } ${
      variation.quantityRemaining === 0
        ? variation.priceCents === 0
          ? "(Full)"
          : "(Sold out)"
        : ""
    }`,
    value: variation.id.toString(),
    disabled: variation.quantityRemaining === 0,
  })),
]

const AddOnsFormFields = ({
  addOn,
  attendee,
  onAddOnChange,
  currencySymbol,
}) => {
  const addOnSelections = attendee.addOnSelections || {}

  const selectedAddOn = addOnSelections[addOn.id] || {}

  const selectedVariation =
    find(addOn.addOnVariations, {
      id: Number(selectedAddOn.addOnVariationId),
    }) || {}

  const hasVariations = addOn.addOnVariations.length > 0

  const hasMultipleAllowed =
    (hasVariations && !isEmpty(selectedVariation) && addOn.allowMultiple) ||
    (!hasVariations && addOn.allowMultiple)

  const hasRemainingQuantity =
    (hasVariations && selectedVariation?.quantityRemaining >= 0) ||
    (!hasVariations && hasMultipleAllowed)

  const handleAddOnChange = ({ target: { value, checked } }) => {
    let attendeeSelectedAddOns = { ...addOnSelections }

    if (!checked && !selectedAddOn.id) {
      delete attendeeSelectedAddOns[addOn.id]
    } else {
      attendeeSelectedAddOns = {
        ...attendeeSelectedAddOns,
        [addOn.id]: {
          ...selectedAddOn,
          addOnId: value,
          addOnableType: hasVariations ? "AddOnVariation" : "AddOn",
          quantity: 1,
          _destroy: !checked || false,
          ...(hasVariations && {
            addOnVariationId: selectedAddOn.addOnVariationId || null,
          }),
        },
      }
    }

    onAddOnChange(attendee, "addOnSelections", attendeeSelectedAddOns)
  }

  const handleAddOnMultipleChange = (value) => {
    const attendeeSelectedAddOns = {
      ...addOnSelections,
      [addOn.id]: {
        ...selectedAddOn,
        quantity: value,
      },
    }

    onAddOnChange(attendee, "addOnSelections", attendeeSelectedAddOns)
  }

  const handleAddOnVariationChange = ({ target: { value } }) => {
    const attendeeSelectedAddOns = {
      ...addOnSelections,
      [addOn.id]: {
        ...selectedAddOn,
        addOnVariationId: value,
        quantity: 1,
      },
    }

    onAddOnChange(attendee, "addOnSelections", attendeeSelectedAddOns)
  }

  const getMaxCount = () => {
    if (!isEmpty(selectedVariation)) return selectedVariation.quantityRemaining
    if (!hasVariations && hasMultipleAllowed) return addOn.quantityRemaining
    return null
  }

  const getMaxCountString = () => {
    const counter = getMaxCount()
    if (counter === null) return
    return counter === 0 ? "Sold out" : `${counter} available`
  }

  const isSelected =
    !isEmpty(selectedAddOn) && !selectedAddOn._destroy ? true : false

  const min = hasVariations ? minBy(addOn.addOnVariations, "priceCents") : addOn
  const max = hasVariations ? maxBy(addOn.addOnVariations, "priceCents") : addOn

  let addOnNameCost = addOn.name

  if (min.priceCents !== max.priceCents) {
    addOnNameCost += ` (${
      min.priceString || `${currencySymbol}0`
    }-${max.priceString.substring(1)})`
  } else if (min.priceCents === max.priceCents && max.priceCents !== 0) {
    addOnNameCost += ` (${max.priceString})`
  }

  const isSoldOut = hasVariations
    ? addOn.addOnVariations.filter(
        (addOnVariation) =>
          addOnVariation.quantityRemaining === null ||
          addOnVariation.quantityRemaining > 0,
      ).length === 0
    : addOn.quantityRemaining !== null && addOn.quantityRemaining === 0

  const isDisabled =
    isSoldOut && (!selectedAddOn.quantity || selectedAddOn.quantity <= 0)

  return (
    <div className="mb-4p">
      <div
        className="d-f ai-fs"
        style={{ minHeight: "24px" }}
        css={addOn.required ? disabledRequiredCheckbox : {}}
      >
        <Checkbox
          key={addOn.id}
          id={`attendee-${attendee.id}_addOn-${addOn.id}`}
          name={`addOn-${addOn.id}`}
          label={addOnNameCost}
          checked={isSelected}
          value={addOn.id}
          onChange={handleAddOnChange}
          disabled={isDisabled || addOn.required}
        />

        {addOn.required && (
          <div className="ml-1 error-badge badge">Required</div>
        )}

        {isSoldOut && (
          <div className="ml-1 badge">
            {addOn.priceCents === 0 ? "Full" : "Sold out"}
          </div>
        )}

        {!isSoldOut &&
          !hasMultipleAllowed &&
          !hasVariations &&
          addOn.quantityRemaining !== null && (
            <div className="ml-1 success-badge badge">
              {addOn.quantityRemaining} available
            </div>
          )}
      </div>

      {isSelected && (hasVariations || hasMultipleAllowed) && (
        <div className="d-f ai-c ml-3 mt-1 mb-2 fw-w" style={{ gap: "0.5rem" }}>
          {hasVariations && (
            <div>
              <Select
                name={`selection-${attendee.id}-${addOn.id}`}
                ariaLabel="select addon"
                className="select w-a"
                style={{ height: 32 }}
                onChange={handleAddOnVariationChange}
                value={selectedAddOn?.addOnVariationId?.toString() || ""}
                options={addOnVariationOptions(addOn.addOnVariations)}
              />
            </div>
          )}

          {hasMultipleAllowed && (
            <NumberSpinner
              max={getMaxCount() || Infinity}
              onChange={handleAddOnMultipleChange}
              size="sm"
              value={selectedAddOn?.quantity || 0}
            />
          )}

          {hasRemainingQuantity && (
            <div
              className={`ml-1 badge ${
                getMaxCount() === 0 ? "" : "success-badge"
              }`}
            >
              {getMaxCountString()}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

AddOnsFormFields.propTypes = {
  addOn: object,
  attendee: object,
  onAddOnChange: func,
  currencySymbol: string,
}

export default AddOnsFormFields

const disabledRequiredCheckbox = css`
  .checkbox[disabled] + .checkbox-label {
    color: unset;
  }
`
